<template>
  <div v-if="hasResource('collection_management')">
    <div class="classification">
      <div class="mg-b-20">
        <el-button
          class="black-btn btn-l"
          @click="handleAdd"
          v-if="hasResource('collection_add')"
        >新增</el-button>
      </div>
      <div class="tabData">
        <el-table
          header-cell-class-name="table-head"
          cell-class-name="table-cell"
          :data="tableData"
          row-key="id"
          class="data-table"
          ref="multipleTable"
          v-loading="tableLoading"
        >
          <el-table-column
            v-for="col in tableColumns"
            :key="col.prop"
            :prop="col.prop"
            :label="col.label"
            :width="col.width"
            :align="col.align"
          >
            <template slot="header" v-if="col.prop == 'operation'">
              <div style="padding-left:22px !important;">操作</div>
            </template>
            <template slot-scope="{$index, row}">
              <div v-if="col.prop =='index'">{{$index + 1}}</div>
              <div v-else-if="col.prop =='sort'">
                <i class="iconfont">&#xe663;</i>
              </div>
              <div v-else-if="col.prop =='image' || col.prop == 'small_image'">
                <img class="width-34-mg-r-13 height-auto" :src="row.small_image" />
                <img class="width-100 height-auto" :src="row.image" />
              </div>
              <div v-else-if="col.prop =='status'">{{row.status == 1? '启用': '停用'}}</div>
              <div v-else-if="col.prop == 'operation'" class="icon-btn">
                <el-button
                  class="preview-color"
                  size="mini"
                  type="text"
                  @click="edit(row)"
                  v-if="hasResource('collection_edit')"
                >
                  <i class="iconfont">&#xe677;</i>编辑
                </el-button>
                <el-button
                  class="preview-color"
                  size="mini"
                  type="text"
                  @click="stop(row)"
                  v-if="row.status && hasResource('collection_disable')"
                >
                  <i class="iconfont">&#xe64b;</i>停用
                </el-button>
                <el-button
                  class="preview-color"
                  size="mini"
                  type="text"
                  @click="start(row)"
                  v-if="!row.status && hasResource('collection_enable')"
                >
                  <i class="iconfont">&#xe64d;</i>启用
                </el-button>
                <el-button
                  class="preview-color"
                  size="mini"
                  type="text"
                  @click="del(row)"
                  v-if="!row.status && hasResource('collection_delete')"
                >
                  <i class="iconfont">&#xe678;</i>删除
                </el-button>
              </div>
              <div v-else>{{row[col.prop]}}</div>
            </template>
          </el-table-column>
        </el-table>
        <!-- <el-pagination
            class="ta-r mg-t-10"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageNumber"
            :page-sizes="[10,20,50]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>-->
      </div>

      <!-- 删除 -->
      <el-dialog
        top="12%"
        :close-on-click-modal="false"
        :visible.sync="dialogVisibleDel"
        width="458px"
        class="pop-dialog"
        :modal-append-to-body="false"
      >
        <span class="del-text">
          您将删除推荐分类
          <span class="del-name">{{setItemOjb.name}}</span>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="confirmDel" class="confirm-button">确 定</el-button>
          <el-button @click="dialogVisibleDel = false" class="cancel-button">取 消</el-button>
        </span>
      </el-dialog>
      <!-- 停用 -->
      <el-dialog
        top="12%"
        :close-on-click-modal="false"
        :visible.sync="dialogVisibleStop"
        width="458px"
        class="pop-dialog"
        :modal-append-to-body="false"
      >
        <span class="del-text">
          您将停用推荐分类
          <span class="del-name">{{setItemOjb.name}}</span>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="confirmStop" class="confirm-button">确 定</el-button>
          <el-button @click="dialogVisibleStop = false" class="cancel-button">取 消</el-button>
        </span>
      </el-dialog>
      <!-- 启用 -->
      <el-dialog
        top="12%"
        :close-on-click-modal="false"
        :visible.sync="dialogVisibleStart"
        width="458px"
        class="pop-dialog"
        :modal-append-to-body="false"
      >
        <span class="del-text">
          您将启用推荐分类
          <span class="del-name">{{setItemOjb.name}}</span>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="confirmStart" class="confirm-button">确 定</el-button>
          <el-button @click="dialogVisibleStart = false" class="cancel-button">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import api from "../../../api/asset";
import Sortable from "sortablejs";
export default {
  data() {
    return {
      header: {
        url: "collections",
      },
      tableColumns: [
        // { prop: "sort", label: "拖拽排序", width: 130, align: "left" },
        { prop: "index", label: "顺序", width: 120, align: "left" },
         { prop: "id", label: "系列ID", width: 180, align: "left" },
        { prop: "name", label: "名称", width: 180, align: "left" },
        { prop: "image", label: "缩略图片", width: 200, align: "left" },
        { prop: "status", label: "状态", width: 130, align: "left" }, // 停用  启用
        { prop: "operation", label: "操作", align: "left", fixed: "right" },
      ],
      dialogVisibleDel: false,
      dialogVisibleStop: false,
      dialogVisibleStart: false,
      tableLoading: false,
      setItemOjb: {},
      tableData: [],
      tableSort: [],
      pageNumber: 1,
      pageSize: 10,
      total: 0,
    };
  },
  methods: {
    init() {
      this.tableLoading = true;
      let data = {
        market: "US",
      };
      api.recClassList(data).then((res) => {
        this.tableLoading = false;
        if (res.data.code === 10200) {
          this.tableData = res.data.data.items;
          this.total = res.data.data.total;
        } else {
          this.$$error(res.data.message);
        }
      });
    },
    tableRowDrop() {
      // 表格中需要实现行拖动，选中tr父级元素
      const tbody = document.querySelector(".el-table__body-wrapper tbody");
      const _this = this;
      Sortable.create(tbody, {
        onEnd({ newIndex, oldIndex }) {
          const currRow = _this.tableData.splice(oldIndex, 1)[0];
          _this.tableData.splice(newIndex, 0, currRow);
          _this.tableSort = _this.tableData;
          _this.motifySort();
        },
      });
    },
    motifySort() {
      this.tableLoading = true;
      let sorts = {};
      for (let i = 0; i < this.tableData.length; i++) {
        let item = this.tableData[i];
        sorts[item.id] = i;
      }
      let data = {
        market: "US",
        sort: sorts,
      };
      api.sortRecClass(data).then((res) => {
        this.tableLoading = false;
        if (res.data.code === 10200) {
          this.init();
        } else {
          this.$$error(res.data.message);
        }
      });
    },
    handleAdd() {
      this.$router.push({
        path: "/collection",
        query: {
          sort: this.total + 1,
        },
      });
    },
    handleDel() {
      if (this.selectTableData.length == 0) {
        this.$$error("请先勾选数据~");
        return;
      }
    },
    edit(row) {
      this.$router.push({
        path: "/collection",
        query: {
          id: row.id,
        },
      });
    },
    stop(row) {
      this.setItemOjb = row;
      this.dialogVisibleStop = true;
    },
    del(row) {
      this.setItemOjb = row;
      this.dialogVisibleDel = true;
    },
    start(row) {
      this.setItemOjb = row;
      this.dialogVisibleStart = true;
    },
    confirmDel() {
      let data = {
        market: "US",
        id: [this.setItemOjb.id],
      };
      api.delRecClass(data).then((res) => {
        if (res.data.code === 10200) {
          this.setItemOjb = {};
          this.dialogVisibleDel = false;
          this.$$success("操作成功!");
          this.init();
        } else {
          this.$$error(res.data.message);
        }
      });
    },
    // 停用推荐系列
    confirmStop() {
      let data = {
        market: "US",
        id: this.setItemOjb.id,
      };
      api.disableAsset(data).then((res) => {
        if (res.data.code === 10200) {
          this.setItemOjb = {};
          this.dialogVisibleStop = false;
          this.init();
          this.$$success("操作成功!");
        } else {
          this.$$error(res.data.message);
        }
      });
    },
    // 启用推荐系列
    confirmStart() {
      let data = {
        market: "US",
        id: this.setItemOjb.id,
      };
      api.enableAsset(data).then((res) => {
        if (res.data.code === 10200) {
          this.setItemOjb = {};
          this.dialogVisibleStart = false;
          this.init();
          this.$$success("操作成功!");
        } else {
          this.$$error(res.data.message);
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.init();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.init();
    },
  },
  created() {
    this.init();
    if (this.hasResource("collection_sort")) {
      this.tableColumns.unshift({
        prop: "sort",
        label: "拖拽排序",
        width: 130,
        align: "left",
      });
    }
  },
  mounted() {
    // 阻止默认行为
    document.body.ondrop = function (event) {
      event.preventDefault();
      event.stopPropagation();
    };
    if (this.hasResource("collection_sort")) {
      this.tableRowDrop();
    }
  },
};
</script>
<style lang="less">
@import "index.less";
</style>



